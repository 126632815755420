import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from "src/app/shared/support-data.service";
import { DockManagementService } from "src/app/docks/shared/dock-management.service";

@Component({
  selector: 'app-schedule-duration-by-weight-dialog',
  templateUrl: './schedule-duration-by-weight-dialog.component.html',
  styleUrls: ['./schedule-duration-by-weight-dialog.component.scss']
})
export class ScheduleDurationByWeightDialogComponent {
  weightRangeList: any[] = [];
  form = this.formBuilder.group({
    weight_range_id: [this.data.weight_range, Validators.required],
    suboperation_id: [this.data.suboperation_id],
    operation_id: [this.data.operation_id],
    duration_id: [this.data.duration_id],
    dock_id: [this.data.dock_id],
    department_id: [this.data.department_id, Validators.required],
  });

  validationMsgs: any[] = [];
  account: any;
  allDataLoaded = false;
  nonEditableField = false;
  showSuboperation = false;
  docks: any[] = [];
  operations: any[] = [];
  vehicleTypes: any[] = [];
  durations: any[] = [];
  durationFiltered: any[] = [];
  operationsFiltered: any[] = [];
  showDuration = true;
  suboperations: any[] = [];
  suboperationsFiltered: any[] = [];
  departments: any[] = [];


  constructor(
    public dialogRef: MatDialogRef<ScheduleDurationByWeightDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    private dockService: DockManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getWeightRange();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.getDepartments();
    this.getOperations();
    this.getSubOperations();
    this.getDuration();
    this.getDocks();
  }

  close() {
    this.dialogRef.close();
  }

  setLimitBySlotSize(value: boolean | null) {
    if (value) {
      this.form.get("limitScheduleSlot")!.setValidators(Validators.required);
    } else {
      this.form.get("limitScheduleSlot")!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      resource: {
        weight_range_id: this.form.value.weight_range_id,
        operation_id: this.form.value.operation_id,
        suboperation_id: this.form.value.suboperation_id,
        duration_id: this.form.value.duration_id,
        dock_id: this.form.value.dock_id,
        department_id: this.form.value.department_id,
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateScheduleDurationByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    } else {
      this.agendamentoService
        .storeScheduleDurationByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    }
  }

  getOperations() {
    this.supportDataService.generic({name: 'operation_type'}).subscribe((r: any) => {
      this.operations = r.data.resource;

      this.operations = this.operations.filter(item => item.use_in_scheduling === true);
      this.operationsFiltered = this.operations.filter(item => item.use_in_scheduling === true);

      this.operationsFiltered = r.data.resource.filter((of: any) => {
        return this.departments.find((item: any) => item.id == of.department_id);
      })
      //Filtra as operações e mostra apenas as operações que possuem campo use_in_scheduling como true

      if(this.data.operation_id){
        this.nonEditableField = true;
        this.departmentChange();
      }
    });
  }

  getDepartments(){
    this.supportDataService.generic({name: 'departments'}).subscribe((r: any) => {
      this.departments = r.data.resource;
      if(this.departments.length === 1) {
        this.form.patchValue({department_id: this.departments[0].id})
      } else if(!this.data.id) {
        //Se não possuir data.id, é um novo bloqueio para um cliente com mais de um departamento
        //Então desativa o campo de Operações até que seja informado um Departamento
        this.form.controls.operation_id.disable();
      }
    });
  }

  getSubOperations(){
    this.supportDataService.generic({name: 'suboperation_type'}).subscribe((r: any) => {
      this.suboperations = r.data.resource;
      if(this.data.suboperations){
        this.nonEditableField = true;
        this.operationChange();   
      } 
    });
  }

  operationChange(){
    if(this.suboperations){
      let operation_id = this.form.get('operation_id')!.value;

      this.setSuboperationsFilter([{operation_id: operation_id}]);
    }
  }

  setSuboperationsFilter(data: any){
    this.suboperationsFiltered = [];
    this.form.patchValue({suboperation_id: [""]})

    //const ft = this.suboperations.filter((item: any) => item.operation_id === operation)

  /*   this.operationsFiltered = r.data.resource.filter((of: any) => {
      return this.departments.find((item: any) => item.id == of.department_id);
    }) */

    this.suboperationsFiltered = this.suboperations.filter((sf: any) => {
      const result = data.find((item: any) => 
        item.operation_id == sf.operation_id
      );
      return result;
    });

    if(this.suboperationsFiltered.length > 0){
      this.showSuboperation = true;
      this.form.get('suboperation_id')!.setValidators([Validators.required]);
      if(this.data.suboperation_id){
        this.form.patchValue({suboperation_id:[this.data.suboperation_id]});
        this.form.controls.suboperation_id.disable();
      }
      this.form.get('suboperation_id')!.updateValueAndValidity();
    } else {
      this.showSuboperation = false;
      this.form.get('suboperation_id')!.setValue(null);
      this.form.get('suboperation_id')!.clearValidators();
      this.form.get('suboperation_id')!.updateValueAndValidity();
    }
  }

  getDuration() {
    this.supportDataService
      .generic({ name: "durations_type" })
      .subscribe((r: any) => {
        console.log(r)
        this.durations = r.data.resource;
      });
  }

  getDocks() {
    this.dockService.index({})
    .subscribe((r: any) => {
      this.docks = r.data;
      console.log(r)
      // if (this.data.docks) {
      //   this.nonEditableField = true;
      // }
    })
  }

  departmentChange(){
    this.setOperationsFilter(this.form.get('department_id')!.value)
  }


  setDurationFilter(operation: number) {
    this.durationFiltered = [];
    this.form.patchValue({ duration_id: "" });
    const ft = this.durations.filter(
      (item: any) => item.operation_id === operation,
    );
    this.durationFiltered = ft;

    if (this.durationFiltered.length > 0) {
      this.showDuration = true;
      
      this.form.patchValue({ duration_id: this.data.duration_id });
      
    } else {
      this.showDuration = false;
      this.form.patchValue({ duration_id: "" });
      this.form.get("duration_id")!.clearValidators();
    }
  }


  setOperationsFilter(department: number) {
    this.operationsFiltered = [];
    this.form.patchValue({ operation_id: "" });
    const ft = this.operations.filter(
      (item: any) => item.department_id === department,
    );
    this.operationsFiltered = ft;
    //Se não for a edição de uma janela, ativa o campo de Operações pois foi informado um Departamento
    if (!this.data.id) {
      this.form.controls.operation_id.enable();
    }

    this.form.patchValue({ operation_id: this.data.operation_id });
  }

  getWeightRange() {
    this.supportDataService
      .generic({ name: "scheduling_weight_range" })
      .subscribe((r: any) => {
        this.weightRangeList = r.data.resource;
      });
  }
}
