import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CheckinService } from "../../checkin/shared/checkin.service";
import { UntypedFormGroup } from "@angular/forms";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { forkJoin, Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { SupportDataService } from "src/app/shared/support-data.service";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";
import { VehicleTypeService } from "src/app/account/shared/vehicle-type.service";

@Component({
  selector: "app-dynamic-dialog",
  templateUrl: "./dynamic-dialog.component.html",
  styleUrls: ["./dynamic-dialog.component.scss"],
})
export class DynamicDialogComponent implements OnInit {
  dialogResult: any = {};
  message: string = "";
  title!: string;
  isDisabled: boolean = false;

  files = [];
  documents: any[] = [];
  loading = false;
  offset!: string;
  form = new UntypedFormGroup({});
  model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  account: any;

  constructor(
    private formlyJsonschema: FormlyJsonschema,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: UntypedFormBuilder,
    public supportDataService: SupportDataService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DynamicDialogComponent>,
    private checkinService: CheckinService,
    private http: HttpClient,
    private vehicleTypeService: VehicleTypeService
  ) {}

  ngOnInit() {
    console.log("-----");
    console.log(this.data);
    const accountStorage: string = localStorage.getItem("account") || "";
    this.account = JSON.parse(accountStorage);

    forkJoin([this.getFormFields()]).subscribe((result) => {
      this.processFormFields(result[0]);
      if (this.data.action == "EDIT" || this.data.id) {
        console.log("preenche form");
        this.fillFormForEdit();
      }
    });
  }

  fillFormForEdit() {
    if(this.data.item.resource){
      this.model = { ...this.data.item.resource };
    }else{
      this.model = { ...this.data.item };
    }
    
  }

  getFormFields(): Observable<any> {
    return this.supportDataService.generic({ name: this.data.form_name });
  }

  processFormFields(r: any) {
    this.fields = r.data.resource;
    // this.fields = [this.formlyJsonschema.toFieldConfig(r.data.resource)];
  }

  // Criar funções para gravar em módulos diferentes
  onSubmit() {
    this.isDisabled = true;
    if (this.data.action.flow_control == "scheduled_checkin") {
      console.log(this.form.value);
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        process: ["data_from_schedule", "data_from_device"],
        ...formData,
      };
      data["status"] = this.data.hasOwnProperty("status")
        ? this.data.action.status
        : "waiting";
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      }
    }
    if (this.data.action.flow_control == "manual_checkin") {
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        process: ["data_from_device"],
        ...formData,
      };
      data["status"] = this.data.hasOwnProperty("status")
        ? this.data.action.status
        : "waiting";
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      }
    }
    if (this.data.action.flow_control == "default") {
      const formData = this.adjustFormData({ ...this.form.value });
      let data = {
        id: this.data.item.id,
        ...formData,
      };
      if (this.data.action.status.length > 0) {
        data.status = this.data.action.status;
      }
      if (!data.id) {
        this.checkinService.newCheckin(data).subscribe((r: any) => {
          this.dialogRef.close();
        });
      } else {
        this.checkinService
          .updateField(this.data.action.urlAPI, data)
          .subscribe({
            next: (r: any) => {
              if (r.status == 200) {
                this.dialogRef.close(r.status);
              }
            },
            error: (res: any) => {
              this.dialogRef.close();
              if (res.error.message) {
                this.snackBar.open(res.error.message, "Fechar", {
                  duration: 3000,
                });
              }
            },
          });
      }
    }
    if (this.data.action.flow_control == "generic") {
      let formData = this.adjustFormData({ ...this.form.value });
      if (this.data.action.action_type == "create") {

        //Função para adicionar o use_additional_info
        this.additionalInfoSave(formData);
        
        if (formData['vehicle_type'] && formData['plate']) { 
          //Função para salvar o novo veículo na vehicle_vehicles
          this.saveNewVehicle(formData);

          //Chama API Generica de Edição / atualização
          this.vehicleTypeService.vehicleTypeByBoard(formData['plate']).subscribe((res) => {
            formData['vehicle_id'] = res[0].id;

            this.genericAPIcreate(formData, this.data.action.target).subscribe(
              (r: any) => {
                this.dialogRef.close();
              },
            );        
          })
        } else {
          //Chama API Generica de Edição / atualização
          this.genericAPIcreate(formData, this.data.action.target).subscribe(
            (r: any) => {
              this.dialogRef.close();
            },
          );
        }
      }

      if(this.data.action.action_type == 'update') {
        formData['id'] = this.data.item.id; 
        
        if (this.data.item.vehicle_type) {
          formData['vehicle_type'] = this.data.item.vehicle_type;
        }
        
        if (this.data.action.model == 'checkin') {
          formData = { ...this.data.item, ...formData};
        }
        
        //Função para adicionar o use_additional_info
        this.additionalInfoSave(formData);

        if (formData['vehicle_type'] && formData['plate']) { 
          //Função para salvar o novo veículo na vehicle_vehicles
          this.saveNewVehicle(formData);

          //Chama API Generica de Edição / atualização
          this.vehicleTypeService.vehicleTypeByBoard(formData['plate']).subscribe((res) => {
            formData['vehicle_id'] = res[0].id;

            this.genericAPIupdate(formData, '', this.data.action.target).subscribe(
              (r: any) => {
                this.dialogRef.close();
              },
            );        
          })
        } else {
          //Chama API Generica de Edição / atualização
          this.genericAPIupdate(formData, '', this.data.action.target).subscribe(
            (r: any) => {
              this.dialogRef.close();
            },
          );
        }

      }
    }
    if (this.data.action.flow_control == "update_password") {
      if(this.form.value.password != this.form.value.confirmedPassword) {
        this.snackBar.open("As senhas não conferem", "Fechar", {
          duration: 3000,
        });
        this.isDisabled = false;
      } else {
        let formData = this.adjustFormData({ ...this.form.value });
        formData = {
          ...formData,
          id: this.data.id
        }
        if (this.data.action.action_type == "update") {
          if (this.data.action.hasOwnProperty("use_additional_info")) {
            const use_info: any[] = this.data.action.use_additional_info;
            use_info.forEach((item: any) => {
              Object.assign(formData, item);
            });
          }
          this.genericAPIcreate(formData, this.data.action.target).subscribe(
            (r: any) => { 
              this.dialogRef.close();
            },
          );
        }
      }
    }
  }

  adjustFormData(form: any) {
    let newFormData: any = {};
    const keys = Object.keys(form);
    keys.forEach((item: any) => {
      if (Array.isArray(form[item])) {
        newFormData[item] = form[item];
      } else if (typeof form[item] === "object" && form[item] != null) {
        newFormData[item] = form[item].id;
      } else {
        newFormData[item] = form[item];
      }
    });

    return newFormData;
  }

  additionalInfoSave(formData: any) {
    if (this.data.action.hasOwnProperty("use_additional_info")) {
      const use_info: any[] = this.data.action.use_additional_info;
      use_info.forEach((item: any) => {
        Object.assign(formData, item);
      });
    }
  }

  saveNewVehicle(vehicleInformation: any) {
    this.vehicleTypeService.vehicleAdd(vehicleInformation).subscribe({
      next: (response) => {
        vehicleInformation['vehicle_id'] = response.id
        console.log('Veículo salvo com sucesso:', response);
      },
      error: (error) => {
        console.error('Erro ao salvar veículo:', error);
      }
    });
  }

  genericAPIupdate(data: any, id?: string, endpoint?: string): Observable<any> {
    const url = `${environment.apiUrl}/${endpoint}/${id}`;

    return this.http.put<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  genericAPIcreate(data: any, endpoint?: string): Observable<any> {
    const url = `${environment.apiUrl}/${endpoint}`;

    return this.http.post<any>(url, data).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}
