import { Injectable } from "@angular/core";
import JourneyStatusTimelineRender from "src/app/timelines/journey-status-timeline/journey-status-timeline-render";
import { SupportDataService } from "./support-data.service";

@Injectable({
  providedIn: "root",
})
export class YardComponentsService {
  constructor(public supportDataService: SupportDataService) {}

  timeline(rowData: any) {
    console.log("Na timeline");
    this.supportDataService.status().subscribe((r: any) => {
      console.log(r);
      const listStatus = r.data.resource;
      const events =
        listStatus
          ?.map((status: any) => {
            const timestamp = rowData.resource?.[`${status.id}_created_at`];
            const description = `Criardo por: ${rowData.resource?.[`${status.id}_created_by`] ?? "Não informado."}`;

            if (!timestamp) return null;

            return {
              id: status.id,
              timestamp: new Date(timestamp),
              title: status.name,
              description,
              itemPosition: "",
            };
          })
          ?.filter((item: any) => item) || [];

      const data = {
        checkin_id: rowData.id,
        operation_name: rowData.operation_name,
        driver_name: rowData.driver_name,
        device_linked: rowData.hasOwnProperty("device_linked")
          ? rowData.device_linked
          : null,
        carrier_name: rowData.carrier_name,
        board_horse: rowData.resource.hasOwnProperty("board_horse")
          ? rowData.resource.board_horse
          : null,
        board_cart: rowData.resource.hasOwnProperty("board_cart")
          ? rowData.resource.board_cart
          : null,
        events,
      };
      JourneyStatusTimelineRender.open(data, {});
    });
  }
}
